.NFCTagMappingPage {
  .edit-btn {
    color: #07b607;
  }
  .remove-btn {
    color: #f00;
  }
  .floating-btn {
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 48px;
    background: #1c3d73;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    transition: background 0.3s;
    cursor: pointer;
    &:hover {
      background: #3362ad;
    }
  }
}
